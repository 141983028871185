import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import Page from './Page';

export default props => {
  return (
    <Layout>
      <Page {...props} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query _PageQuery { 
     store: site { 
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    } 
  }
`; 
